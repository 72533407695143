<template>
  <div>
    <section class="service container container__mini2">
      <h2 class="service__title" v-if="data">{{ data.title }}</h2>

      <div class="service__wrap" v-if="data">
        <div class="service__wrap--img">
          <div class="service__wrap--img--inner">
            <v-lazy-image
              :src="url + data.image"
              width="239"
              height="239"
              alt="image-logo"
            />
          </div>
        </div>
        <div class="service__wrap--descr">
          <h3 class="service__wrap--descr--title" v-if="data.sub_title">
            {{ data.sub_title }}
          </h3>
          <div
            class="service__wrap--descr--text"
            v-html="data.sub_description"
          ></div>
        </div>
      </div>

      <div class="service__wrap--descr--text" v-html="data.description"></div>

      <div class="service__two-img">
        <div class="service__two-img--wrap" v-if="data">
          <div class="service__two-img--wrap--inner">
            <v-lazy-image
              :src="url + data.image_1"
              width="720"
              height="532"
              alt="image-1"
            />
          </div>
        </div>
        <div class="service__two-img--wrap" v-if="data">
          <div class="service__two-img--wrap--inner">
            <v-lazy-image
              :src="url + data.image_2"
              width="455"
              height="532"
              alt="image-2"
            />
          </div>
        </div>
      </div>

      <div class="service__wrap reverse">
        <div class="service__wrap--img" v-if="data">
          <div class="service__wrap--img--inner">
            <v-lazy-image
              :src="url + data.image_3"
              width="720"
              height="532"
              alt="image-3"
            />
          </div>
        </div>
        <div class="service__wrap--descr" v-if="data">
          <h3 class="service__wrap--descr--title">{{ data.title_1 }}</h3>
          <div
            class="service__wrap--descr--text"
            v-html="data.description_1"
          ></div>
        </div>
      </div>

      <div class="service__wrap--descr--text" v-html="data.description_2"></div>

      <h3 class="service__subtitle">{{ data.file_title }}</h3>

      <div class="service__look" v-if="data">
        <img src="~@/assets/images/pdf.svg" width="49" height="61" alt="pdf" />
        <a class="service__look--all" v-if="data.pdf" :href="data.pdf.url">{{
          t("view_all_services")
        }}</a>

        <div
          class="service__wrap--descr--text"
          v-html="data.file_description"
        ></div>

        <button class="service__look--btn" @click="modalToggle">
          {{ t("order_a_service") }}
        </button>
      </div>
    </section>
    <transition name="popup">
      <Popup-order :id="data.id" v-if="showModal" @click="showModal = false" />
    </transition>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const PopupOrder = defineAsyncComponent(() =>
  import("@/components/PopupOrder.vue")
);
import axios from "axios";
import VLazyImage from "v-lazy-image";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    PopupOrder,
    VLazyImage,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  props: {
    url: {
      type: String,
      employee: Object,
      default: process.env.VUE_APP_API,
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      showModal: false,
      showForm: false,
      data: [],
    };
  },
  methods: {
    modalToggle: function (e) {
      e.preventDefault();
      this.showModal = !this.showModal;
    },
    async getServicePage() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/service-page",
          config
        );
        this.data = response.data.data;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.getServicePage();
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.service {
  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 42, 34);
    line-height: 1;
    color: #000000;
    text-transform: uppercase;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 60, 30);
  }

  &__subtitle {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 32, 24);
    color: #000000;
    text-transform: uppercase;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 45, 20);
  }

  &__wrap {
    display: grid;
    @include property("gap", 65, 20, true, 1670, 1024);
    @include property("margin-bottom", 45, 15);
    justify-content: center;

    @media (min-width: 768px) {
      &:not(.reverse) {
        grid-template-columns: 1.5fr 1fr;
      }

      &.reverse {
        grid-template-columns: 1fr 1.5fr;
      }
    }

    &--img {
      background: url("~@/assets/images/bg-card.jpg") no-repeat;
      background-size: cover;
      width: 100%;
      align-self: flex-start;

      .reverse & {
        order: 2;
      }

      @media (max-width: 767.98px) {
        max-width: 500px;
        margin: 0 auto;
      }

      &--inner {
        position: relative;
        padding-bottom: 76%;
      }

      img {
        position: absolute;

        .service__wrap:not(.reverse) & {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50%;
          height: 50%;
          max-height: 239px;
          max-width: 239px;
        }

        .reverse & {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &--descr {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &--title {
        font-weight: 700;
        @include property("font-size", 32, 24);
        letter-spacing: 0.64px;
        color: #f9b80e;
        margin-bottom: 0.7em;
      }

      &--text {
        @include property("font-size", 24, 20);
        letter-spacing: 0.48px;
        color: #1c1c1c;
      }
    }
  }

  &__two-img {
    display: grid;
    @include property("gap", 45, 20, true, 1670, 768);
    @include property("margin-bottom", 45, 20);

    @media (min-width: 768px) {
      grid-template-columns: 1.5fr 1fr;
    }

    &--wrap {
      &--inner {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #000000;
          opacity: 0.25;
          z-index: 3;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 2;
        }
      }

      &:nth-of-type(1) {
        .service__two-img--wrap--inner {
          max-width: 720px;
          margin: 0 auto;
          padding-bottom: 74%;
        }
      }

      &:nth-of-type(2) {
        .service__two-img--wrap--inner {
          max-width: 480px;
          margin: 0 auto;
          padding-bottom: 111%;
        }
      }
    }
  }

  &__look {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 630px;
    margin: 0 auto;
    @include property("margin-bottom", 100, 30);
    text-align: center;

    &--all {
      position: relative;
      text-align: center;
      @include property("font-size", 24, 20);
      line-height: 1.1;
      letter-spacing: 1.2px;
      color: #f9b80e;
      max-width: 175px;
      margin-top: 38px;
      @include property("margin-bottom", 45, 20);

      &::before {
        content: "";
        position: absolute;
        top: -15px;
        left: 50%;
        transform: translateX(-50%);
        width: 80px;
        height: 3px;
        background: #f9b80e;
        pointer-events: none;
      }
    }

    &--btn {
      text-align: center;
      font-weight: 700;
      letter-spacing: 0.8px;
      color: #ffffff;
      background: #f9b80e;
      box-shadow: 0px 0px 15px #f9b80e73;
      border: 1px solid #f9b80e;
      border-radius: 5px;
      padding: 10px 20px;
      margin-top: 20px;
      transition: all 0.3s linear;

      @media (hover: hover) {
        &:hover {
          background: #ff8c09;
          box-shadow: 0px 0px 15px #f9b80e80;
          border: 1px solid #ff8c09;
        }
      }
    }

    p {
      text-align: center;
      font-size: 20px;
      letter-spacing: 0.4px;
      color: #1c1c1c;
    }

    img {
      display: block;
      width: 49px;
    }
  }
}
</style>
